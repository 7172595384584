footer {
  background-color: #353535;
  padding: 20px;
}
footer a {
  color: #b3b3b3;
}
footer a:hover {
  color: #9a9a9a;
}

body {
  background-color: #1b1b1b;
  color: #b3b3b3;
  font-family: sans-serif;
  margin: 0;
}

.content {
  padding: 20px;
}

input, button {
  background: #2c2c2c;
  border: none;
  height: 2.5rem;
  box-sizing: border-box;
  padding-left: 0.75rem;
  color: white;
}

button {
  background: #464646;
  cursor: pointer;
}
button:hover {
  background: #5f5f5f;
}

.errorLabel {
  color: darkred;
}

.row {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}
.row .label {
  width: 80%;
  background-color: #313131;
  padding: 20px;
}
.row .status {
  text-align: center;
  width: 20%;
  float: right;
  padding: 20px;
  color: white;
}
.row .status.success {
  background-color: green;
}
.row .status.error {
  background-color: red;
}
/*# sourceMappingURL=index.1cd714c2.css.map */
