footer {
  background-color: lighten(#1b1b1b, 10%);
  padding: 20px;

  a {
    color: #b3b3b3;

    &:hover {
      color: darken(#b3b3b3, 10%)
    }
  }
}