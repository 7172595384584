@import "footer";

body {
  background-color: #1b1b1b;
  color: #b3b3b3;
  font-family: sans-serif;
  margin: 0;
}

.content{
  padding: 20px;
}
input, button {
  background: #2c2c2c;
  border: none;
  height: 2.5rem;
  box-sizing: border-box;
  padding-left: .75rem;
  color: white;
}

button{
  background: lighten(#2c2c2c, 10%);
  cursor: pointer;

  &:hover {
    background: lighten(#2c2c2c, 20%);
  }
}

.errorLabel{
  color: darkred;
}

.row{
  width: 100%;
  margin-bottom: 10px;
  display: flex;

  .label{
    width: 80%;
    background-color: lighten(#2c2c2c, 2%);
    padding: 20px;
  }

  .status {
    text-align: center;
    width: 20%;
    float: right;
    padding: 20px;
    color: white;

    &.success {
      background-color: green;
    }
    &.error {
      background-color: red;
    }
  }
}